import React from 'react';

export const MarketPlaceHighlights = ({ items }) => {
  return (
    <div className="mp-block mp-highlights">
      <div className="mp-highlights__content">
        <div className="mp-highlights__items">
          {items.map((highlight, index) => {
            return <div key={`highlight-marketplace-${index}`} className="mp-highlight">
              <div className="mp-highlight__icon">
                <img src={ highlight.highlight_image.url } alt="Marketplace icon"/>
              </div>
              <div className="mp-highlight__info">
                <h3 className='mp-highlight__title'>{highlight.highlight_title.text}</h3>
                <div className="mp-highlight__description" dangerouslySetInnerHTML={{ __html: highlight.highlight_description.html }} />
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  )
};
