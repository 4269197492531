import React from 'react';
import { Link } from 'gatsby';

import amazon from '../../../static/assets/images/marketplace/amazon.svg';
import mercadoLibre from '../../../static/assets/images/marketplace/mercado_libre.svg';

export const MarketPlaceItems = ({ items }) => {
  return (
    <div className="mp-block mp-items">
      <div className="mp-items__content">
        <div className="mp-items__items">
          {items.map((item, index) => {
            return <div key={`items-marketplace-${index}`} className="mp-item">
              <div className="mp-item__image">
                <img src={ item.product_image.url } alt="product"/>
              </div>
              <div className="mp-item__line" />
              <div className="mp-item__title" dangerouslySetInnerHTML={{ __html: item.product_name.html }} />
              <div className="mp-item__marketplace">
                <Link className={`mp-item__link ${item.amazon_url.url ? "" : "mp-item__disabled"}`} to={ item.amazon_url.url }>
                  <img src={amazon} alt="amazon"/>
                </Link>
                <div className="mp-item__separator" />
                <Link className={`mp-item__link ${item.mercado_libre_url.url ? "" : "mp-item__disabled"}`} to={ item.mercado_libre_url.url }>
                  <img src={mercadoLibre} alt="mercado libre"/>
                </Link>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  )
};
