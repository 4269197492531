import { useStaticQuery, graphql } from "gatsby"
export const usePrismMarketplacePage = () => {
  const prismic = useStaticQuery(
    graphql`
      query{
        allPrismicMarketplace {
          edges {
            node {
              data {
                title {
                  html
                }
                cover_description {
                  html
                }
                cover_image {
                  url
                  xs {
                    url
                  }
                  md {
                    url
                  }
                  lg {
                    url
                  }
                }
                body {
                  ... on PrismicMarketplaceBodyStorytelling {
                    id
                    slice_type
                    primary {
                      story_telling {
                        html
                      }
                    }
                  }
                  ... on PrismicMarketplaceBodyMarketplaceItems {
                    id
                    slice_type
                    items {
                      product_name {
                        html
                      }
                      product_image {
                        url
                      }
                      mercado_libre_url {
                        url
                      }
                      amazon_url {
                        url
                      }
                    }
                  }
                  ... on PrismicMarketplaceBodyHighlights {
                    id
                    slice_type
                    items {
                      highlight_title {
                        text
                      }
                      highlight_description {
                        html
                      }
                      highlight_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return prismic.allPrismicMarketplace
}