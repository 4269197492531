import React from 'react';

import CalorexLogo from '../../components/svg/commons/calorex_logo';

export const MarketPlaceStorytelling = ({ story_telling }) => {
  return (
    <div className="mp-block mp-storytelling">
      <div className="mp-storytelling__container">
        <CalorexLogo fill="#ffffff" fillbody="#ffffff"/>
        <div className="mp-storytelling__decription mp-padding" dangerouslySetInnerHTML={{ __html: story_telling.html }} />
      </div>
    </div>
  )
};
