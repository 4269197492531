import React from 'react';

import { Picture } from 'react-responsive-picture';

import "../../styles/pages/market_place/market_place.scss";

export const MarketPlaceCover = ({ title, image }) => {
  return (
    <div className="mp-block mp-cover">
      <div className="mp-cover__description" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="mp-cover__image">
        <Picture
          sources = {[
            {
              srcSet: image.xs.url,
              media: "(max-width: 768px)",
            },
            {
              srcSet: image.md.url,
              media: "(max-width: 1024px)",
            },
            {
              srcSet: image.lg.url,
              media: "(max-width: 1365px)",
            },
            {
              srcSet: image.url,
              media: "(min-width: 1366px)",
            }
          ]}
        />
      </div>
    </div>
  )
};
