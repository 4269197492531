import React from "react"
import Layout from "../components/layout"

import { usePrismMarketplacePage } from '../hooks/use_prims_marketplace'

import { MarketPlaceCover } from "../content/market_place/_market_place_cover";
import { MarketPlaceStorytelling } from '../content/market_place/_market_place_storytelling';
import { MarketPlaceItems } from "../content/market_place/_market_place_items";
import { MarketPlaceHighlights } from '../content/market_place/_market_place_highlights';

import "../styles/pages/market_place/market_place.scss"

const MarketPlace = () => {

  const marketplace = usePrismMarketplacePage().edges
  const data = marketplace[0].node.data

  return (
    <Layout>
      <MarketPlaceCover title={data.cover_description.html} image={data.cover_image} />
      {data.body.map((block) => {
        if (block.slice_type === 'storytelling') return <MarketPlaceStorytelling key={block.id} {...block.primary} />
        if (block.slice_type === 'marketplace_items') return <MarketPlaceItems key={block.id} items={block.items} {...block.primary} />
        if (block.slice_type === 'highlights') return <MarketPlaceHighlights key={block.id} items={block.items} {...block.primary} />
        return null
      })}
    </Layout>
  )
}

export default MarketPlace